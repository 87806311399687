
    var riot = require('riot')
    
riot.tag2('duomap', '<gmap name="gmap" postcode="SK1 3JR" title="Wilde Risk" zoom="15" hidemarker="{true}" key="AIzaSyA6IwdPV1BqaNFJsnk6gM2CeKIhtuJIhfI" primary="#dd0000" secondary="#292929" class="mymap"> <div>Whitworth House 28<br>Charles St<br>Stockport<br>Cheshire<br>SK1 3JR</div> </gmap> <gmap-markers markers="{locations}" linkedmap="gmap" icon="/images/assets/mapicon.png"></gmap-markers>', '', '', function(opts) {
this.locs = this.opts.locations || {};

this.locations = [];

if (this.locs.length > 0) {
  this.locarr = this.locs.split("\n");
  this.locarr.map((function(_this) {
    return function(loc, id) {
      var regex;
      regex = /[a-z]{1,2}[0-9]{1,2}[a-z]? ?[0-9]{1,2}[a-z]{1,2}/gi;
      return _this.locations.push({
        "postcode": loc.match(regex).toString(),
        "name": loc.replace(loc.match(regex).toString(), "")
      });
    };
  })(this));
}
});
    
  