
    var riot = require('riot')
    
riot.tag2('nlsignup', '<div if="{showform}"> <form action="/duocms/api/newsletters/subscribe" method="POST"> <input type="hidden" name="_csrf" value="{csrf_token}"> <legend>Subscribe now</legend> <div class="row form-group"> <label class="col-sm-2 control-label text-right">Email</label> <div class="col-sm-10"> <input type="text" name="email" placeholder="Email address" required class="form-control"> </div> </div> <div class="row form-group"> <label class="col-sm-2 control-label text-right">Company</label> <div class="col-sm-10"> <input type="text" name="company" placeholder="Company" required class="form-control"> </div> </div> <div class="row form-group"> <div class="col-xs-12 signup-button"> <button class="btn btn-primary btn-lg pull-right">Submit</button> </div> </div> </form> </div> <div if="{!showform}"> <p>[newsletter signup form will appear here]</p> </div>', '', '', function(opts) {
this.csrf_token = window.csrf;

this.showform = false;

this.on('mount', function() {
  if (!window.location.search.match(/editmode/)) {
    this.showform = true;
  }
  return this.update();
});
});
    
  