# window.riot = riot = require 'riot'
# require 'riot-kit/lib/slideshow'
# require "riot-kit/lib/img_loader"
# require 'riot-kit/lib/form'
# require 'riot-kit/lib/twitter'
# require 'riot-kit/lib/modal'
# require 'riot-kit/lib/form'
# require 'riot-kit/lib/ecommerce'
# require 'riot-kit/lib/pagination'
# require 'riot-kit/lib/dateformat'
# require 'riot-kit/lib/booking-calendar'
# require 'riot-kit/lib/dropdown'
# require 'riot-kit/lib/gmap'
# require('riot-kit/lib/auth/forgot-password')

# require './tags/loginbar.tag'
# require './tags/ecommercebar.tag'
# require './tags/navigation.tag'
# require './tags/mydetails.tag'
# require './tags/mypassword.tag'
# require './tags/signup.tag'
# require './tags/tags-input.tag'x
# require './tags/comments_input.tag'
# require './tags/comments_output.tag'
# require './tags/recaptcha.tag'
# require './tags/pricetag.tag'
# require './libs/slideshow.coffee'

# riot.mount('*')
# document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

window.riot = riot = require 'riot'
$ = jQuery = window.jQuery = window.$ = require "jquery"
require 'riot-kit/lib/slideshow'
require 'riot-kit/lib/img_loader'
require 'riot-kit/lib/form'
require 'riot-kit/lib/tabs'
require 'riot-kit/lib/gmap'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/modal'

require './libs/slideshow.coffee'

require 'intersection-observer'
Isotope = require 'isotope-layout'
imagesLoaded = require 'imagesloaded'
require('smoothscroll-polyfill').polyfill()
require 'owl.carousel'

require './tags/navigation.tag'
require './tags/twitter.tag'
require './tags/tags-input.tag'
# require './tags/date_ranges.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/duomap.tag'
require './tags/gmap.tag'
# require './tags/thermometer.tag'
# require './tags/linechart.tag'
# require './tags/calltoaction.tag'
# require './tags/hotels-register.tag'
require './tags/nlsignup.tag'
require './tags/resourcefilter.tag'
require './tags/navsetting.tag'

require './tags/videomodal.tag'

require 'details-polyfill'

require './tags/eventbrite.tag'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

shortslide = document.querySelectorAll('body.index .slideshow-index .slideshow-shallow')
if shortslide && shortslide.length>0
  document.querySelector('.slideshow-index').setAttribute('style','height:500px !important')


# intersection observer
# ctathreshold = 0.5
# icongrid = document.querySelector('.ctaicongrid')
# window.ctaobserverCallback = (entries, observer) ->
#   i = 0
#   while i < entries.length
#     entry = entries[i]
#     console.log "cta entry",entry
#     if entry.intersectionRatio > ctathreshold
#       icongrid.className = icongrid.className.replace(RegExp(/ ?fullsize ?/), '') + ' fullsize'
#       # entry.target.className = entry.target.className.replace(RegExp(/ ?enter ?/), '') + ' enter'
#     else
#       icongrid.className = icongrid.className.replace(RegExp(/ ?fullsize ?/), '') + ''
#       # entry.target.className = entry.target.className.replace(RegExp(/ ?enter ?/), '') + ''
#     i++
#   return

# ctaoptions = threshold: ctathreshold
# ctaobserver = new IntersectionObserver(ctaobserverCallback, ctaoptions)
# ctatarget = document.querySelector('.ctaicongrid-locator')
# ctaobserver.observe(ctatarget)

tabradios = document.querySelectorAll('input.trainingtabs')
tabinput = document.querySelector('#trainingtabshidden')
if tabradios
  [].map.call tabradios,(tr)=>
    tr.addEventListener 'click',(e)=>
      tabinput.value = tr.value || ""

showrelatedcbx = document.querySelectorAll('input.showrelated')
showrelatedinp = document.querySelector('#showrelatedhidden')
related = document.querySelector('#relatedresources')
if showrelatedcbx
  [].map.call showrelatedcbx,(sr)=>
    sr.addEventListener 'click',(e)=>
      showrelatedinp.value = sr.checked ? 1 : 0
      related.classList.toggle('visible')
      related.classList.toggle('hidden')
      # else
      #   related.classList.add('hidden')
      #   related.classList.remove('visible')

slideshows = document.querySelectorAll('.duoslideshow')
if slideshows.length > 0
  [].map.call slideshows, (ss)=>
    slidecount = ss.querySelectorAll('.duoslide')
    if slidecount.length == 1
      ss.classList.add('singleslide')

navcontent = document.querySelectorAll('.navsetting')
drops = document.querySelectorAll('li[riot-tag="dropdown"] .dropdown-menu')
# lis = document.querySelectorAll('li[riot-tag="dropdown"]:not(:first-child)')
lis = document.querySelectorAll('li[riot-tag="dropdown"]')

console.log "nc",navcontent

if navcontent
  [].map.call navcontent, (nc,index)=>
    nc.addEventListener 'click',(e)=>
      if nc.querySelector('div[contenteditable="true"]')
        if drops[index]
          drops[index].classList.add('editingnav')
          lis.forEach (li,i)=>
            if i != index
              li.addEventListener 'mouseover',(event)=>
                drops[index].classList.remove('editingnav')

# if navcontent
#   console.log "navcontent",navcontent
#   navcontent.addEventListener 'click',(e)=>
#     if navcontent.querySelector('div[contenteditable="true"]')
#       if drops[0]
#         drops[0].classList.add('editingnav')
#         lis.forEach (li)=>
#           li.addEventListener 'mouseover',(event)=>
#             drops[0].classList.remove('editingnav')


# [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
#   if subnav
#     subnav.addEventListener "click",(event)->
#       if subnav.getAttribute('riot-tag') == "dropdown"
#         if subnav.className.match(/open/)
#           subnav.querySelector('a').addEventListener 'click',(e)->
#             if e.target.parentNode.className.match(/open/)
#               window.location.href = subnav.querySelector('a').href
#         else
#           subnav.querySelector('a').removeEventListener 'click'
#           event.preventDefault() 


# mobile nav stuff
mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
mo = new mmutationObserver (mutations)->
  m = 0
  # mutations.forEach (mutation)->
  while m < mutations.length
    mutation = mutations[m]
    if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
        subnav.addEventListener 'click',(e)->
          if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
            e.preventDefault()
            subnav.querySelector('a,i').addEventListener 'click',(e)->
              window.location.href = subnav.querySelector('a').href
            subsecs = subnav.querySelectorAll('.subnav a')
            if subsecs.length > 0
              [].slice.call(subsecs).map (sec)=>
                sec.addEventListener 'click',(e)->
                  window.location.href = sec.href
    m++

mo.observe(document.querySelector('body'),{attributes:true})


# if window.Duocms?.editmode
iboxes = document.querySelectorAll('.showimagecheck')
if iboxes
  [].forEach.call iboxes,(ib)->
    ib.onclick = (e)->
      document.querySelector('#'+ib.getAttribute('data-opt')).value = +Boolean(ib.checked)

body = document.querySelector('body')
slideshow = document.querySelector('slideshow.homepage')
tabs = document.querySelector('.indextabs')
navbar = document.querySelector('.navbar-default')
donutmapareas = document.querySelectorAll('.donutmap area')
donutarrows = document.querySelectorAll('.donut-arrow')
donuttabs = document.querySelectorAll('.donut-tabs .nav-tabs li a')

if tabs
  tablinks = tabs.querySelectorAll('.nav-tabs li a')
  [].forEach.call tablinks,(tablink)->
    tablink.addEventListener 'click',->
      document.querySelector('#tabanchor').scrollIntoView({behavior:'smooth'})

# if donuttabs
#   [].forEach.call donuttabs,(dlink,dindex)->
#     dlink.addEventListener 'click',()->
#       donutmapareas[dindex].click()

# if donutmapareas
#   [].forEach.call donutmapareas,(dma)->
#     tabid = dma.getAttribute('data-tab')
#     index = tabid-1
#     target = document.querySelector('.donut-arrow-0'+tabid)
#     dma.addEventListener 'click',->
#       [].forEach.call donutarrows,(da)=>
#         if !da.className.match(dma.getAttribute('data-target'))
#           da.className = da.className.replace(/ ?active ?/,' ')
#       target.className = target.className.replace(/ ?active ?/,' ')+' active'
#       donuttabs[index].click()
#     dma.addEventListener 'mouseover',->
#       target.className = target.className.replace(/ ?hover ?/,'')+' hover'
#     dma.addEventListener 'mouseout',->
#       target.className = target.className.replace(/ ?hover ?/,'')


searchtrigger = document.querySelector('.searchtrigger')
searchform = document.querySelector('.searchbutton .searchform')
searchtrigger.addEventListener 'click',->
  if searchform.className.match(/show/)
    searchform.className = searchform.className.replace(/ ?show ?/,'')
  else
    searchform.className = searchform.className.replace(/ ?show ?/,'')+' show'

# mobile nav stuff
mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
mo = new mmutationObserver (mutations)->
  m = 0
  # mutations.forEach (mutation)->
  while m < mutations.length
    mutation = mutations[m]
    if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
        subnav.addEventListener 'click',(e)->
          if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
            e.preventDefault()
            subnav.querySelector('a,i').addEventListener 'click',(e)->
              window.location.href = subnav.querySelector('a').href
            subsecs = subnav.querySelectorAll('.subnav a')
            if subsecs.length > 0
              [].slice.call(subsecs).map (sec)=>
                sec.addEventListener 'click',(e)->
                  window.location.href = sec.href
    m++

mo.observe(document.querySelector('body'),{attributes:true})


# intersection observer
ithreshold = 0.1
dthreshold = 1.0
window.observerCallback = (entries, observer) ->
  i = 0
  while i < entries.length
    entry = entries[i]
    if entry.intersectionRatio > ithreshold
      entry.target.className = entry.target.className.replace(RegExp(/ ?enter ?/), '') + ' enter'
      observer.unobserve entry.target
    i++
  return

setTimeout(=>
  testimonials = document.querySelectorAll('.dl_testimonials')
  if testimonials.length > 0
    [].slice.call(testimonials).map (tst)=>
      tst.classList.add "owl-carousel"
    setTimeout ()=>
      $(document).ready ()=>
        $('.owl-carousel').owlCarousel
          items:1
          # pagination:false
          nav:true
          autoplay:true
          loop:true
          # margin: 100
          # navContainer: '#artists-nav'
          # responsive:{
          #   0 : {items:1}
          #   992 : {items:1}
          # }
    ,500  
,500)



options = threshold: ithreshold
observer = new IntersectionObserver(observerCallback, options)
targets = document.querySelectorAll('.intersect')
t = 0
if !document.querySelector('body').className.match(/editing/)
  while t < targets.length
    observer.observe targets[t]
    t++

ctalocator = document.querySelector('.ctaicongrid-locator')
ctagrid = document.querySelector('.ctaicongrid')
window.checkscroll = ->
  if (document.querySelector('.slideshow-index'))
    slideshowwrapper = document.querySelector('.slideshow-index')
    slideshow = document.querySelector('.slideshow-index .duoslideshow')
    if window.innerWidth < 768
      slideshowwrapper.style.height = window.innerHeight+"px"
      slideshow.style.height = window.innerHeight+"px"
    else if shortslide && shortslide.length>0
      slideshowwrapper.style.height = "500px"
      slideshow.style.height = "500px"
    else
      slideshowwrapper.style.height = ""
      slideshow.style.height = ""
  bodyYpos = body.getBoundingClientRect().top
  if Math.abs(bodyYpos) > 100
    navbar.className = navbar.className.replace(/ ?short ?/,'')+' short '
  else
    navbar.className = navbar.className.replace(/ ?short ?/,'')
  if slideshow
    if slideshow.getBoundingClientRect().bottom < 80
      navbar.className = navbar.className.replace(/ ?opaque ?/,'')+' opaque '
    else
      navbar.className = navbar.className.replace(/ ?opaque ?/,'')
    imgs = slideshow.querySelectorAll('.img')
    if imgs
      imgy = Math.abs(bodyYpos)/2
      if bodyYpos < 0
        [].forEach.call imgs,(img)->
          img.style.transform = 'translate3d(0,'+imgy+'px,0)'
      else
        [].forEach.call imgs,(img)->
          img.style.transform = 'translate3d(0,0,0)'
  # console.log ctalocator.getBoundingClientRect().top, Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  # console.log bodyYpos,ctagrid.getBoundingClientRect().height
  if ctagrid
    if Math.abs(bodyYpos) >= 200
      if !ctagrid.classList.contains('fullsize') && !ctagrid.classList.contains('resizing')
        ctagrid.classList.add('resizing')
        setTimeout(=>
          ctagrid.classList.add('fullsize')
        ,400)
        setTimeout(=>
          ctagrid.classList.remove('resizing')
        ,800)
    if Math.abs(bodyYpos) < 200
      if ctagrid.classList.contains('fullsize')
        ctagrid.classList.add('resizing')
        setTimeout(=>
          ctagrid.classList.remove('fullsize')
        ,400)
        setTimeout(=>
          ctagrid.classList.remove('resizing')
        ,800)



window.layoutblocks = ->
  isonav = []
  navitems = document.querySelectorAll('.isotope-nav')
  [].map.call navitems, (ni,index)=>
    console.log ni, index
    isonav[index] = new Isotope '.isotope-nav-'+index,{
      itemSelector: '.grid-item-nav'
      percentPosition: true
      masonry: {
        columnWidth: '.grid-sizer'
      }
    }
    setTimeout(=>
      isonav[index].layout()
      console.log "laying out "+index
    ,500)

  isotope_div = document.querySelector('.isotope')
  if isotope_div
    isoblog = new Isotope '.isotope',{
      itemSelector: '.grid-item'
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-sizer'
      }
    }
    imgLoadBlog = imagesLoaded($('.isotope'))
    imgLoadBlog.on 'progress',->
      isoblog.layout()
    imgLoadBlog.on 'always',->
      port = document.querySelectorAll('.isotope')
      [].forEach.call port,(p)->
        p.className = p.className.replace(/ ?loaded ?/,' ')+' loaded '

viewportSize = =>
  vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  aspect = vw/vh
  if aspect > 2.3
    document.querySelector('body').classList.add('widescreen')
  else
    document.querySelector('body').classList.remove('widescreen')


griditems = document.querySelectorAll('.grid-item')
if griditems
  [].map.call griditems, (gi)=>
    gi.addEventListener 'click',(e)->
      link = gi.querySelector('a[href]')
      if link && window.location.href.search(/editmode=true/) < 0
        window.location.href = link

checkFormDates = =>
  tabselects = document.querySelectorAll('.event-tabs .tab-content form select')
  if tabselects && !body.classList.contains('editing')
    tabselects.forEach (ts)=>
      regex = new RegExp(/date/gi)
      namecheck = ts.name.match(regex)
      if namecheck && ts.children.length
        daterex = new RegExp(/(\d{1,2}).*?(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec).*?(\d{4})/gi)
        months = ["jan","feb","mar","apr","may","jun","jul","aug","sep","oct","nov","dec"]
        [].map.call ts.children, (opt)=>
          datecheck = daterex.exec(opt.innerText)
          if datecheck && datecheck.length > 0
            now = new Date()
            evdate = new Date(datecheck[3],months.indexOf(datecheck[2].toLowerCase()),datecheck[1],12,0,0)
            if evdate < now
              opt.parentNode.removeChild(opt)

['scroll','load','resize'].forEach (type)->
  window.addEventListener type,viewportSize
  window.addEventListener type,checkscroll
  if type=="load"
    checkFormDates()
  if type=="load" && (document.querySelector('.isotope') || document.querySelector('.isotope-nav'))
    layoutblocks()
  if type=="load" && (document.querySelector('.slideshow-index')) && window.innerWidth < 768
    slideshowwrapper = document.querySelector('.slideshow-index')
    slideshow = document.querySelector('.slideshow-index .duoslideshow')
    slideshowwrapper.style.height = window.innerHeight+"px"
    slideshow.style.height = window.innerHeight+"px"