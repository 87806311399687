
    var riot = require('riot')
    
riot.tag2('navsetting', '<p>hello</p> <modal name="menusetting" title="Navigation editable block content" bssize="md" onclose="{true}"><yield></yield></modal>', '', '', function(opts) {
this.on('mount', (function(_this) {
  return function() {
    return console.log(_this.opts.editmode);
  };
})(this));
});
    
  