
    var riot = require('riot')
    
riot.tag2('eventbrite', '<div id="eventbrite-widget-container"></div>', '.booingdone { padding: 10px; }', '', function(opts) {
window.addEventListener('EBWidgets', (function(_this) {
  return function() {
    return console.log("eb loaded");
  };
})(this));

this.on('mount', function() {
  var firstScriptTag, scrtag;
  console.log("eventbrite", opts);
  if (!window.location.search.match(/editmode/)) {
    scrtag = document.createElement('script');
    scrtag.src = "https://www.eventbrite.co.uk/static/widgets/eb_widgets.js";
    firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(scrtag, firstScriptTag);
    if (scrtag.readyState) {
      scrtag.onreadystatechange = (function(_this) {
        return function() {
          if (scrtag.readyState === "loaded" || scrtag.readyState === "complete") {
            scrtag.onreadystatechange = null;
            return setup();
          }
        };
      })(this);
    } else {
      scrtag.onload = (function(_this) {
        return function() {
          return _this.setup();
        };
      })(this);
    }
  }
  return this.update();
});

this.setup = function() {
  return window.EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: this.opts.ebid,
    iframeContainerId: 'eventbrite-widget-container',
    iframeContainerHeight: 425,
    onOrderComplete: this.exampleCallback
  });
};

this.exampleCallback = function() {
  console.log('Order complete!');
};
});
    
  