
    var riot = require('riot')
    
riot.tag2('gmap-script', '', '', '', function(opts) {
var ref;

if (window.gmapscript != null) {
  return;
}

window.gmapscript = riot.observable();

if (((ref = window.google) != null ? ref.maps : void 0) == null) {
  gmapscript.one('loadscript', (function(_this) {
    return function() {
      var script;
      script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?v=3&callback=gmapscript.init' + (opts.key ? "&key=" + opts.key : '');
      return document.body.appendChild(script);
    };
  })(this));
}

window.gmapscript.init = (function(_this) {
  return function() {
    return window.gmapscript.trigger('loaded');
  };
})(this);
});
riot.tag2('gmap', '<gmap-script key="{opts.key}"></gmap-script> <div class="map"></div> <div class="info"><yield></yield></div>', 'gmap .map { width: 100%; height: 100%; } gmap .info { display: none; }', '', function(opts) {
var self;

self = this;

this.on('update', function() {
  return setTimeout((function(_this) {
    return function() {
      var ref;
      if (_this.root.getBoundingClientRect().width) {
        return typeof google !== "undefined" && google !== null ? (ref = google.maps) != null ? ref.event.trigger(_this.map, 'resize') : void 0 : void 0;
      }
    };
  })(this));
});

this.on('mount', function() {
  this.root.trigger = this.trigger;
  this.gmap = window.gmapscript;
  this.loading = true;
  this.styleArray = opts.stylearray || [
    {
      "featureType": "landscape",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": opts.primary
        }
      ]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": opts.primary
        }, {
          "lightness": -30
        }
      ]
    }, {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }, {
      "featureType": "road.highway",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    }, {
      "featureType": "road.arterial",
      "stylers": [
        {
          "color": opts.secondary
        }
      ]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    }, {
      "featureType": "road.arterial",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": opts.secondary
        }, {
          "lightness": -30
        }
      ]
    }, {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": opts.secondary
        }, {
          "lightness": 30
        }
      ]
    }, {
      "featureType": "road.local",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#666666"
        }
      ]
    }
  ];
  if ((typeof google !== "undefined" && google !== null ? google.maps : void 0) != null) {
    return this.load();
  } else if (this.gmap) {
    this.gmap.one('loaded', (function(_this) {
      return function() {
        return _this.load();
      };
    })(this));
    this.gmap.trigger('loadscript');
    return this.gmap.on('linkmap', (function(_this) {
      return function(map, cb) {
        if (map === opts.name) {
          return cb(_this.map);
        }
      };
    })(this));
  } else {
    throw new Error('Google Maps Api hasn\'t been loaded');
  }
});

this.on('getdirections', function(from, to) {
  if (opts.showdirections != null) {
    return this.directionsService.route({
      origin: from,
      destination: to,
      travelMode: google.maps.DirectionsTravelMode.DRIVING,
      unitSystem: google.maps.DirectionsUnitSystem.IMPERIAL,
      provideRouteAlternatives: true
    }, (function(_this) {
      return function(response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          _this.directionsDisplay.setDirections(response);
          return _this.update();
        }
      };
    })(this));
  }
});

this.load = (function(_this) {
  return function() {
    var centre;
    _this.loading = false;
    _this.geocoder = new google.maps.Geocoder();
    centre = opts.postcode || "M50 2ST";
    _this.map = new google.maps.Map(_this.root.querySelectorAll('.map')[0], {
      zoom: parseInt(opts.zoom, 10) || 6,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      panControl: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      overviewMapControl: false,
      backgroundColor: 'transparent',
      gestureHandling: 'cooperative',
      scrollwheel: false
    });
    if (opts.showdirections != null) {
      _this.directionsService = new google.maps.DirectionsService();
      _this.directionsDisplay = new google.maps.DirectionsRenderer();
      _this.directionsDisplay.setMap(_this.map);
      if (opts.directionspanel != null) {
        _this.directionsDisplay.setPanel(_this.parent.root.querySelectorAll(opts.directionspanel)[0]);
      }
    }
    _this.map.mapTypes.set("Edited", new google.maps.StyledMapType(_this.styleArray));
    _this.map.setMapTypeId('Edited');
    if ((opts.lat != null) && (opts.long != null)) {
      _this.loadMap(new google.maps.LatLng(opts.lat, opts.long));
    } else {
      _this.geocoder.geocode({
        'address': centre
      }, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          return _this.loadMap(results[0].geometry.location);
        }
      });
    }
    return _this.update();
  };
})(this);

this.loadMap = (function(_this) {
  return function(location) {
    var marker;
    _this.map.setCenter(location);
    if (opts.hidemarker == null) {
      marker = new google.maps.Marker({
        map: _this.map,
        position: location,
        animation: google.maps.Animation.DROP,
        icon: opts.icon != null ? opts.icon : void 0,
        title: opts.title != null ? opts.title : void 0
      });
      if (_this.root.querySelectorAll('.info')[0].innerHTML != null) {
        google.maps.event.addListener(marker, 'click', function() {
          var infowindow;
          infowindow = new google.maps.InfoWindow({
            content: _this.root.querySelectorAll('.info')[0].innerHTML
          });
          return infowindow.open(_this.map, marker);
        });
      }
    }
    return _this.update();
  };
})(this);
});
riot.tag2('gmap-directions', '<gmap-script></gmap-script> <form onsubmit="{getdirections}" class="form-inline"> <div class="form-group"> <label>Enter your postcode for driving directions&nbsp; <input type="text" name="directionsTo" placeholder="postcode" class="form-control"> <button type="submit" class="btn btn-success">Get Directions</button> </label> </div> </form> <div class="mappanel"></div>', 'gmap-directions .mappanel { margin: 20px 0; } gmap-directions .mappanel img { padding: 2px; } gmap-directions .mappanel table { border: 1px solid #e5e5e5; width: 100%; }', '', function(opts) {
this.on('mount', function() {
  this.gmap = window.gmapscript;
  if ((typeof google !== "undefined" && google !== null ? google.maps : void 0) != null) {
    return this.load();
  } else if (this.gmap) {
    this.gmap.one('loaded', (function(_this) {
      return function() {
        return _this.load();
      };
    })(this));
    return this.gmap.trigger('loadscript');
  } else {
    throw new Error('Google Maps Api hasn\'t been loaded');
  }
});

this.load = (function(_this) {
  return function() {
    _this.directionsService = new google.maps.DirectionsService();
    _this.directionsDisplay = new google.maps.DirectionsRenderer();
    _this.gmap.trigger('linkmap', opts.linkedmap, function(map) {
      return _this.directionsDisplay.setMap(map);
    });
    return _this.directionsDisplay.setPanel(_this.root.querySelectorAll('.mappanel')[0]);
  };
})(this);

this.getdirections = function() {
  return this.directionsService.route({
    origin: this.directionsTo.value,
    destination: opts.postcodeto,
    travelMode: google.maps.DirectionsTravelMode.DRIVING,
    unitSystem: google.maps.DirectionsUnitSystem.IMPERIAL,
    provideRouteAlternatives: true
  }, (function(_this) {
    return function(response, status) {
      if (status === google.maps.DirectionsStatus.OK) {
        return _this.directionsDisplay.setDirections(response);
      }
    };
  })(this));
};
});
riot.tag2('gmap-markers', '<gmap-script></gmap-script>', '', '', function(opts) {
this.markers = [];

this.listenerHandle = [];

this.bounds = {};

this.on('mount', function() {
  this.gmap = window.gmapscript;
  this.cluster = opts.cluster;
  if (opts.cluster === true && !window.MarkerClusterer) {
    console.log("please include MarkerClusterer in your bundle ie require 'riot-kit/utils/markerclusterer'");
    this.cluster = false;
  }
  if ((typeof google !== "undefined" && google !== null ? google.maps : void 0) != null) {
    return this.load();
  } else if (this.gmap) {
    this.gmap.one('loaded', (function(_this) {
      return function() {
        return _this.load();
      };
    })(this));
    return this.gmap.trigger('loadscript');
  } else {
    throw new Error('Google Maps Api hasn\'t been loaded');
  }
});

this.load = (function(_this) {
  return function() {
    _this.geocoder = new google.maps.Geocoder();
    if (opts.onready) {
      opts.onready();
    }
    return _this.gmap.trigger('linkmap', opts.linkedmap, function(map) {
      _this.map = map;
      return _this.showMarkers();
    });
  };
})(this);

this.showMarkers = (function(_this) {
  return function() {
    var ref;
    _this.clearMarkers();
    _this.bounds = new google.maps.LatLngBounds();
    return typeof opts !== "undefined" && opts !== null ? (ref = opts.markers) != null ? ref.forEach(function(marker, idx) {
      var gMarker, iconUrl, iconpre, latLng;
      _this.postcode = marker.postcode || false;
      if (opts.icon != null) {
        iconUrl = opts.icon;
      }
      if (!opts.icon) {
        iconpre = "//maps.google.com/mapfiles/";
        iconUrl = opts.lettermarkers != null ? iconpre + 'marker' + String.fromCharCode("A".charCodeAt(0) + idx) + '.png' : iconpre + 'marker.png';
      }
      if (_this.postcode) {
        return _this.geocoder.geocode({
          'address': _this.postcode
        }, function(results, status) {
          var gMarker, latLng;
          if (status === google.maps.GeocoderStatus.OK) {
            latLng = results[0].geometry.location;
            gMarker = new google.maps.Marker({
              map: _this.map,
              animation: google.maps.Animation.DROP,
              icon: iconUrl,
              position: results[0].geometry.location
            });
            _this.updatemap(marker, gMarker, latLng);
            return _this.bounds.extend(latLng);
          }
        });
      } else {
        latLng = new google.maps.LatLng(marker.latitude, marker.longitude);
        gMarker = new google.maps.Marker({
          map: _this.map,
          animation: google.maps.Animation.DROP,
          icon: iconUrl,
          position: latLng
        });
        _this.updatemap(marker, gMarker, latLng);
        return _this.bounds.extend(latLng);
      }
    }) : void 0 : void 0;
  };
})(this);

this.updatemap = (function(_this) {
  return function(usermarker, newmarker, ll) {
    var markerClusterer;
    _this.bounds.extend(ll);
    _this.listenerHandle.push(google.maps.event.addListener(newmarker, 'click', function() {
      var infowindow;
      infowindow = new google.maps.InfoWindow({
        content: usermarker.name
      });
      return infowindow.open(_this.map, newmarker);
    }));
    if ((typeof opts !== "undefined" && opts !== null ? opts.onmarkerhover : void 0) != null) {
      _this.listenerHandle.push(google.maps.event.addListener(newmarker, 'mouseover', (function() {
        return opts.onmarkerhover(usermarker);
      })));
    }
    _this.markers.push(newmarker);
    _this.map.fitBounds(_this.bounds);
    _this.zoomChangeBoundsListener = function() {
      return google.maps.event.addListenerOnce(_this.map, 'bounds changed', function(event) {
        if (_this.map.getZoom()) {
          return _this.map.setZoom(14);
        }
      });
    };
    if (_this.cluster) {
      if (opts.clusterstyle) {
        _this.clusteropts = opts.clusterstyle;
      }
      return markerClusterer = new window.MarkerClusterer(_this.map, _this.markers, _this.clusteropts);
    }
  };
})(this);

this.clearMarkers = (function(_this) {
  return function() {
    var ref, ref1, ref2;
    _this.listenerHandle.forEach(function(listener) {
      return google.maps.event.removeListener(listener);
    });
    if ((ref = _this.markers) != null) {
      ref.forEach(function(marker) {
        return marker.setMap(null);
      });
    }
    if ((ref1 = _this.listenerHandle) != null) {
      ref1.length = 0;
    }
    return (ref2 = _this.markers) != null ? ref2.length = 0 : void 0;
  };
})(this);

this.setZoom = (function(_this) {
  return function(centre) {
    if (((centre != null ? centre.latitude : void 0) != null) && ((centre != null ? centre.longitude : void 0) != null)) {
      centre = new google.maps.LatLng(centre.latitude, centre.longitude);
    }
    if (centre != null) {
      _this.bounds.extend(centre);
    }
    _this.map.fitBounds(_this.bounds);
    if (_this.map.getZoom() > 12) {
      return _this.map.setZoom(12);
    }
  };
})(this);
});
riot.tag2('gmap-search', '<gmap-script></gmap-script> <gmap-markers markers="{markers}" linkedmap="{opts.linkedmap}" onmarkerclick="{opts.onmarkerclick}"></gmap-markers> <form onsubmit="{getmarkers}" class="form-inline"> <div class="form-group"> <label>Enter your postcode <input type="text" name="postcode" placeholder="postcode" class="form-control"> <select type="text" name="radius" placeholder="radius" class="form-control"> <option value="10">10 miles</option> <option value="20">20 miles</option> <option value="30">30 miles</option> <option value="40">40 miles</option> <option value="50">50 miles</option> </select> <button type="submit" if="{!processing}" class="btn btn-success">Find</button> <button type="submit" if="{processing}" class="btn btn-success"><i class="fa fa-spinner fa-spin"></i></button> </label> </div> </form>', 'gmap-search .marker-template { display: none; }', '', function(opts) {
var resource;

resource = require('riot-kit/utils/apiResource.coffee');

this.api = new resource(opts.src, true);

this.markers = [];

this.on('mount', function() {
  this.gmap = window.gmapscript;
  if ((typeof google !== "undefined" && google !== null ? google.maps : void 0) != null) {
    return this.load();
  } else if (this.gmap) {
    this.gmap.one('loaded', (function(_this) {
      return function() {
        return _this.load();
      };
    })(this));
    return this.gmap.trigger('loadscript');
  } else {
    throw new Error('Google Maps Api hasn\'t been loaded');
  }
});

this.load = (function(_this) {
  return function() {
    _this.gmap.trigger('linkmap', opts.linkedmap, function(map) {
      return _this.map = map;
    });
    return _this.geocoder = new google.maps.Geocoder();
  };
})(this);

this.getmarkers = (function(_this) {
  return function() {
    var within;
    within = +_this.radius.value || opts.within;
    _this.processing = true;
    return _this.api.get({
      postcode: _this.postcode.value,
      within: within
    }, function(data, err) {
      var centre;
      _this.processing = false;
      _this.update();
      if ((err != null) && (opts.onmarkersfound != null)) {
        opts.onmarkersfound({}, {}, 'Postcode not found');
      }
      if (err == null) {
        if (opts.onmarkersfound != null) {
          opts.onmarkersfound(data.centre, data.markers, err);
        }
        centre = new google.maps.LatLng(data.centre.latitude, data.centre.longitude);
        _this.map.setCenter(centre);
        console.log("mapppp!!!");
        if (_this.marker) {
          _this.marker.setMap(null);
        }
        if (_this.circle) {
          _this.circle.setMap(null);
        }
        _this.markers = data.markers;
        _this.marker = new google.maps.Marker({
          map: _this.map,
          position: centre,
          animation: google.maps.Animation.DROP,
          icon: '/images/assets/hmklogo.png'
        });
        _this.circle = new google.maps.Circle({
          center: centre,
          radius: within * 1609.334,
          fillOpacity: 0.1,
          fillColor: '#024CE7',
          strokeOpacity: 1.0,
          strokeColor: '#024CE7',
          strokeWeight: 1.0,
          map: _this.map
        });
        _this.circle.bindTo('center', _this.marker, 'position');
        _this.map.fitBounds(_this.circle.getBounds());
        _this.update();
        return _this.tags['gmap-markers'].showMarkers();
      }
    });
  };
})(this);
});
    
  